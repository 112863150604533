.testCaseItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.description {
  flex-basis: 50%;
  font-weight: bold;
  font-size: 16px;
}

.resultComponent {
  flex-basis: 10%;
}

.radioDiv {
  flex-basis: 10%;
  align-items: center;
}

.radioButton {
  position: relative;
  left: 120px;
}

.sourceButton {
  width: 80px;
  border: 1.5px solid rgba(183, 183, 183, 1);
  border-radius: 3px;
  font-size: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 27px;
  font-weight: 500;
  cursor: pointer;
}

.sourceButton_container {
  flex-basis: 23%;
    margin-right: 8px;
}

.buttonBasic {
  height: 28px;
  border-radius: 3px;
  border: solid 1.5px rgba(183, 183, 183, 1);
  font-size: 10px;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
}