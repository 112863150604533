.mainBoard {
  margin: auto;
}

.descriptionCard {
  background-color: #ffffff;
  padding: 10px 32px;
  border-radius: 5px;
  margin-right: 5px;
  box-sizing: border-box;
  flex-basis: 60%;
  display: flex;
}

.cardHeader {
  font-weight: bold;
  font-size: 20px;
}

.cardContent {
  padding-right: 120px;
}

.cardSnippet {
  margin-bottom: 20px;
}

.itemGroup {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 15px;
}

.itemGroupDotted {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  margin-bottom: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}

.buttonBasic {
  height: 28px;
  border-radius: 3px;
  border: solid 1.5px rgba(183, 183, 183, 1);
  font-size: 10px;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 6px;
}

.descriptionContent {
  max-width: 400px;
  padding-right: 20px;
}

.green {
  border: 1px solid green;
  padding: 2px;
  margin: 2px;
}

.red {
  border: 1px solid red;
  padding: 2px;
  margin: 2px;
}

.yellow {
  border: 1px solid yellow;
  padding: 2px;
  margin: 2px;
}

.conclusionRow {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  justify-content: flex-start;
  gap: 10px;
}

.leftCard {
  flex-basis: 60%;
}

.rightCard {
  flex-basis: 40%;
  margin-top: 55px;
  display: flex;
  justify-content: space-around;
  border: solid 1px #bfbfbf;
  border-radius: 4px;
  height: 102px;
  background-color: rgba(248, 248, 248, 1);
}

.possibleResults {
  padding-top: 14px;
}
